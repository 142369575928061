import React from 'react';

export const NutritionSummary = ({ totalNutrition, onItemClick }) => (
  <div className="nutrition-summary">
    <NutritionItem label="Calories" value={Math.round(totalNutrition.calories)} isMain onClick={() => onItemClick('calories')} />
    <NutritionItem label="Protein" value={Math.round(totalNutrition.protein)} onClick={() => onItemClick('protein')} />
    <NutritionItem label="Carbs" value={Math.round(totalNutrition.carbs)} onClick={() => onItemClick('carbs')} />
    <NutritionItem label="Fat" value={Math.round(totalNutrition.fat)} onClick={() => onItemClick('fat')} />
  </div>
);

const NutritionItem = ({ label, value, isMain = false, onClick }) => (
  <div className="nutrition-item" onClick={onClick}>
    {isMain ? <h1>{value}</h1> : <h2>{value}</h2>}
    <div>{label}</div>
  </div>
);
