// utils/api.js

/**
 * Fetches nutrition data for a given meal from the API.
 * @param {string} meal - The meal description to get nutrition data for.
 * @returns {Promise<Object>} - A promise that resolves to the nutrition data.
 * @throws {Error} - If the API call fails.
 */
export const getNutritionData = async (meal) => {
    try {
      const response = await fetch('/api/nutrition', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ meal }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const responseData = await response.json();
      return JSON.parse(responseData);
    } catch (error) {
      console.error('Error fetching nutrition data:', error);
      throw new Error('Failed to fetch nutrition data');
    }
  };
  
  // You can add other API-related functions here in the future