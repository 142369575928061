import { useState, useEffect, useCallback } from 'react';
import { initDB, getMealDataForDate, storeMealData, deleteFoodItem, getMealDataForDateRange } from '../utils/indexedDB';
import { getNutritionData } from '../utils/api';

export const useNutritionData = (selectedDate) => {
  const [db, setDB] = useState(null);
  const [meals, setMeals] = useState([]);
  const [showItemizedList, setShowItemizedList] = useState(false);
  const [lastWeeksData, setLastWeeksData] = useState([]);

  useEffect(() => {
    initDB().then(setDB);
  }, []);

  const updateMeals = useCallback(() => {
    if (db) {
      getMealDataForDate(db, selectedDate).then(setMeals);
    }
  }, [db, selectedDate]);

  useEffect(() => {
    updateMeals();
  }, [updateMeals]);

  useEffect(() => {
    if (db) {
      const endDate = new Date(selectedDate);
      const startDate = new Date(endDate);
      startDate.setDate(startDate.getDate() - 6);

      getMealDataForDateRange(db, startDate, endDate).then((data) => {
        const processedData = data.map(day => ({
          date: day.date,
          totalNutrition: day.meals.reduce(
            (total, meal) => ({
              calories: total.calories + (meal.calories || 0),
              protein: total.protein + (meal.protein || 0),
              carbs: total.carbs + (meal.carbohydrates || 0),
              fat: total.fat + (meal.fat || 0),
            }),
            { calories: 0, protein: 0, carbs: 0, fat: 0 }
          )
        }));
        setLastWeeksData(processedData);
      });
    }
  }, [db, selectedDate]);

  const handleMealSubmit = async (meal) => {
    try {
      const nutritionData = await getNutritionData(meal);
      await storeMealData(db, nutritionData, selectedDate);
      updateMeals();
    } catch (error) {
      console.error('Error processing meal:', error);
    }
  };

  const handleDeleteFoodItem = async (itemId) => {
    await deleteFoodItem(db, itemId);
    updateMeals();
  };

  const totalNutrition = meals.reduce(
    (total, meal) => ({
      calories: total.calories + (meal.calories || 0),
      protein: total.protein + (meal.protein || 0),
      carbs: total.carbs + (meal.carbohydrates || 0),
      fat: total.fat + (meal.fat || 0),
    }),
    { calories: 0, protein: 0, carbs: 0, fat: 0 }
  );

  return {
    meals,
    totalNutrition,
    showItemizedList,
    setShowItemizedList,
    handleMealSubmit,
    handleDeleteFoodItem,
    lastWeeksData,
  };
};