import React from 'react';

export const DateSelector = ({ decrementDate, incrementDate, formattedDate, previousDate, nextDate }) => {
  return (
    <div className="date-selector">
      <button className="date-button" onClick={decrementDate}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 18L9 12L15 6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
      <div className="date-display">{formattedDate}</div>
      <button className="date-button" onClick={incrementDate}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 18L15 12L9 6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
    </div>
  );
};
