import { useState, useCallback, useMemo } from 'react';

const formatDate = (date) => {
  return date.toLocaleDateString('en-CA'); // Returns YYYY-MM-DD format in local timezone
};

const formatDisplayDate = (date) => {
  return date.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });
};

const parseDate = (dateString) => {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);  // month is 0-indexed in Date constructor
};

export const useDate = () => {
  const [selectedDate, setSelectedDate] = useState(() => {
    const now = new Date();
    return formatDate(now);
  });

  const incrementDate = useCallback(() => {
    setSelectedDate(prevDate => {
      const date = parseDate(prevDate);
      date.setDate(date.getDate() + 1);
      return formatDate(date);
    });
  }, []);

  const decrementDate = useCallback(() => {
    setSelectedDate(prevDate => {
      const date = parseDate(prevDate);
      date.setDate(date.getDate() - 1);
      return formatDate(date);
    });
  }, []);

  const formattedDate = useMemo(() => {
    const date = parseDate(selectedDate);
    return formatDisplayDate(date);
  }, [selectedDate]);

  const previousDate = useMemo(() => {
    const date = parseDate(selectedDate);
    date.setDate(date.getDate() - 1);
    return formatDisplayDate(date);
  }, [selectedDate]);

  const nextDate = useMemo(() => {
    const date = parseDate(selectedDate);
    date.setDate(date.getDate() + 1);
    return formatDisplayDate(date);
  }, [selectedDate]);

  return {
    selectedDate,
    setSelectedDate,
    incrementDate,
    decrementDate,
    formattedDate,
    previousDate,
    nextDate
  };
};