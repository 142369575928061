const SimpleChart = ({ data, macro }) => {
  const width = 300;
  const height = 150;
  const padding = 20;
  const chartWidth = width - 2 * padding;
  const chartHeight = height - 2 * padding;
  const fixedLength = 7; // Define a fixed length for the data array

  // Pad the data array with zeros if it has fewer elements than the fixed length
  const paddedData = [...data];
  while (paddedData.length < fixedLength) {
    paddedData.push({ value: 0 });
  }

  const maxValue = Math.max(...paddedData.map(d => d.value));
  const minValue = Math.min(...paddedData.map(d => d.value));

  const xScale = (index) => padding + (index * (chartWidth / (fixedLength - 1)));
  const yScale = (value) => height - padding - ((value - minValue) / (maxValue - minValue)) * chartHeight;

  const points = paddedData.map((d, i) => ({
    x: xScale(i),
    y: yScale(d.value)
  }));

  // Function to generate a smooth curve using cubic bezier
  const smoothCurve = (points) => {
    if (points.length < 2) return "";
    
    const result = [];
    let i, p0, p1, p2, p3;

    result.push(`M${points[0].x},${points[0].y}`);

    for (i = 1; i < points.length - 2; i++) {
      p0 = points[i - 1];
      p1 = points[i];
      p2 = points[i + 1];
      p3 = points[i + 2];

      const x1 = p1.x + (p2.x - p0.x) / 6;
      const y1 = p1.y + (p2.y - p0.y) / 6;
      const x2 = p2.x - (p3.x - p1.x) / 6;
      const y2 = p2.y - (p3.y - p1.y) / 6;

      result.push(`C${x1},${y1} ${x2},${y2} ${p2.x},${p2.y}`);
    }

    // Last segment
    p0 = points[points.length - 3];
    p1 = points[points.length - 2];
    p2 = points[points.length - 1];
    const x1 = p1.x + (p2.x - p0.x) / 6;
    const y1 = p1.y + (p2.y - p0.y) / 6;

    result.push(`C${x1},${y1} ${p2.x},${p2.y} ${p2.x},${p2.y}`);

    return result.join(" ");
  };

  const pathD = smoothCurve(points);

  if(paddedData.every(p => p.value === 0)) {
    return null;
  }

  return (
    <div 
      style={{
        position: 'relative',
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      <svg width={width} height={height}>
        <text x={width / 4} y={10} textAnchor="middle" stroke="white" fontSize="12">{macro}</text>
        <path d={pathD} fill="none" stroke="white" strokeWidth="2" />
        {points.length > 0 && (
          <circle 
            cx={points[points.length - 1].x} 
            cy={points[points.length - 1].y} 
            r="8" 
            fill="none" 
            stroke="white" 
            strokeWidth="2" 
            className="pulse" 
          />
        )}
      </svg>
    </div>
  );
};

export default SimpleChart;
