const dbName = 'NutritionTrackerDB';
const dbVersion = 1;
const storeName = 'meals';

export const initDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, dbVersion);

    request.onerror = (event) => {
      console.error('IndexedDB error:', event.target.error);
      reject(event.target.error);
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      const mealStore = db.createObjectStore(storeName, {
        keyPath: 'id',
        autoIncrement: true,
      });
      mealStore.createIndex('date', 'date', { unique: false });

      const goalStore = db.createObjectStore('goals', { keyPath: 'id' });
    };
  });
};

export const storeGoals = (db, goals) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['goals'], 'readwrite');
    const objectStore = transaction.objectStore('goals');
    const request = objectStore.put({ ...goals, id: 'dailyGoals' });

    request.onerror = (event) => reject(event.target.error);
    request.onsuccess = () => resolve();
  });
};

export const getGoals = (db) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['goals'], 'readonly');
    const objectStore = transaction.objectStore('goals');
    const request = objectStore.get('dailyGoals');

    request.onerror = (event) => reject(event.target.error);
    request.onsuccess = (event) => resolve(event.target.result || {});
  });
};

export const storeMealData = (db, nutritionData, date) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], 'readwrite');
    const objectStore = transaction.objectStore(storeName);
    const parsedDate = new Date(date).toISOString().split('T')[0];

    (nutritionData.items ?? [nutritionData]).forEach((item) => {
      const request = objectStore.add({
        date: parsedDate,
        ...item,
      });

      request.onerror = (event) => reject(event.target.error);
    });

    transaction.oncomplete = () => resolve();
  });
};

export const getMealDataForDate = (db, date) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], 'readonly');
    const objectStore = transaction.objectStore(storeName);
    const index = objectStore.index('date');
    const range = IDBKeyRange.only(date);

    const request = index.getAll(range);

    request.onerror = (event) => reject(event.target.error);
    request.onsuccess = (event) => resolve(event.target.result);
  });
};

export const deleteFoodItem = (db, itemId) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], 'readwrite');
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.delete(itemId);

    request.onerror = (event) => reject(event.target.error);
    request.onsuccess = () => resolve();
  });
};

export const getMealDataForDateRange = (db, startDate, endDate) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], 'readonly');
    const objectStore = transaction.objectStore(storeName);
    const index = objectStore.index('date');
    const range = IDBKeyRange.bound(
      startDate.toISOString().split('T')[0],
      endDate.toISOString().split('T')[0]
    );

    const request = index.getAll(range);

    request.onerror = (event) => reject(event.target.error);
    request.onsuccess = (event) => {
      const meals = event.target.result;
      const groupedMeals = meals.reduce((acc, meal) => {
        if (!acc[meal.date]) {
          acc[meal.date] = [];
        }
        acc[meal.date].push(meal);
        return acc;
      }, {});

      const result = Object.entries(groupedMeals).map(([date, meals]) => ({
        date,
        meals
      }));

      resolve(result);
    };
  });
};