import React, { useState } from 'react';

const ListItem = ({ meal, onDelete }) => {
  const [showDelete, setShowDelete] = useState(0);

  const incrementState = () => {
    setShowDelete((deleteState) => {
      if (deleteState === 2) {
        return 0;
      }
      return deleteState + 1;
    });
  };


  return (
    <li key={meal.id} onClick={incrementState}>
      {showDelete === 0 && meal.item}
      {showDelete === 1 && `${Math.round(meal.calories)} cal, ${Math.round(meal.protein)}g protein, ${Math.round(meal.carbohydrates)}g carbs, ${Math.round(meal.fat)}g fat`}
      {showDelete === 2 && <> go back <button onClick={() => onDelete(meal.id)}>Delete</button></>}
    </li>
  );
};

const ItemizedList = ({ meals, onDelete, show }) => {
  return (
    <div className={`itemized-list-container`}>
      <ul>
        {meals.map((meal) => (
          <ListItem key={meal.id} meal={meal} onDelete={onDelete} />
        ))}
      </ul>
    </div>
  );
};

export default ItemizedList;